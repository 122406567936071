import { getApiUrlByName } from 'project/helpers';
import { apiNames } from 'project/general';
import { baseApi } from 'project/api';

const GET_PLEDGE = getApiUrlByName(apiNames.GET_PLEDGE);

export class pledgeWallService {

    static getPledge = (pledgeId) => {

        const config = { headers: { 'Content-Type': 'application/json' } };
        return baseApi
            .post(GET_PLEDGE, pledgeId, config)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });

    }

}

