export const apiNames = Object.freeze({
    PRODUCT_VARIANT: 'productVariant',
    ADD_PRODUCT: 'addProduct',
    UPDATE_PRODUCT: 'updateProduct',
    REMOVE_PRODUCT: 'removeProduct',
    PRODUCT_LIST: 'productList',
    VALIDATE_CHECKOUT_ADDRESSES: 'validateCheckoutAddresses',
    TO_CHECKOUT: 'toCheckout',
    TO_PAYMENT: 'toPayment',
    PROFILE_ORDERS: 'profileOrders',
    CURRENT_USER_NAVIGATION: 'currentUserNavigation',
    PROFILE_EVENTS: 'profileEvents',
    EVENT_EXPANDED_COBPONENT: 'eventExpandedComponent',
    PAYPAL_CAPTURE: 'paypalCapture',
    PAYPAL_CREATE_ORDER: 'paypalCreateOrder',
    ADD_COUPON_CODE: 'addCouponCode',
    GET_COOKIE_LEVEL: 'getCookieLevel',
    SET_COOKIE_LEVEL: 'setCookieLevel',
    GET_SERVICE_CATEGORIES: 'GetServiceCategories',
    FIND_SERVICES_BY_NAME: 'findServicesByName',
    FIND_EDITABLE_SERVICES_BY_NAME: 'findEditableServicesByName',
    FIND_NON_EDITABLE_SERVICES_BY_NAME: 'findNonEditableServicesByName',
    GET_SERVICE_LISTING_REQUEST: 'getServiceListingRequest',
    GET_COORDINATES: 'getCoordinates',
    GET_ASD_SELF_SERVICE_USER_CONTEXT: 'getSelfServiceUserContext',
    ENGAGEMENT_SHARE: 'engagementShare',
    ENGAGEMENT_LIKE: 'engagementLike',
    GET_PLEDGE: 'getPledge',
});

export const notificationTypes = Object.freeze({
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
});
